import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';

import { IDashboardInterfaces } from '../../interfaces/interfaces';
import { KidService } from './kid/kid.service';
import { SKIPLOADING } from '../interceptor/httpi.interceptor';
export interface App {
  onCondition: boolean;
  never: boolean;
  packname: string;
  name: string;
  category: string;
  always: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class DashService {
  constructor(private http: HttpClient, private kidservice: KidService) {}

  getDate(yesterday = false) {
    var today = new Date();
    var dd;
    if (yesterday) {
      dd = String(today.getDate() - 1).padStart(2, '0');
    }
    dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    const date = dd + '-' + mm + '-' + yyyy;
    return date;
  }

  sortDateData(dates: any[]) {
    dates.forEach((v, i) => {
      v = `${v.slice(3, 5)}/${v.slice(0, 2)}/${v.slice(6, v.length)}`;
      dates[i] = new Date(v);
    });
    dates.sort(
      (date1, date2) =>
        new Date(date1).setHours(0, 0, 0, 0) -
        new Date(date2).setHours(0, 0, 0, 0)
    );
    const final = [];
    dates.forEach((v, i) => {
      final.push(
        v.toLocaleDateString('fr-FR', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      );
    });

    dates.forEach((v, i) => {
      dates[i] = v.toLocaleDateString('fr-FR').split('/').join('-');
    });
    return { dates, final };
  }

  getChallengeDetails(userId: string, challengeId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/getChallenge/${userId}/${challengeId}`
    );
  }
  verifyParent(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/verifyParent', data);
  }
  getDownloadLink(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/downloadLink');
  }
  getPlugAvailability(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/plugAvailability');
  }
  sendEmailForToken(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/generateToken', data);
  }
  getKidDash(
    kidId: string,
    fromKids = false
  ): Observable<{
    kidsInfo: IDashboardInterfaces.IKidInterfaces;
    kidsApps: { [x: string]: App[] };
  }> {
    return this.http.get(
      `${environment.apiUrl}/api/getdash/${kidId}?first=${fromKids}`,
      {
        context: new HttpContext().set(SKIPLOADING, false),
      }
    ) as Observable<{
      kidsInfo: IDashboardInterfaces.IKidInterfaces;
      kidsApps: { [x: string]: App[] };
    }>;
  }
  getDefis(): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/getDefis', {
      user_id: this.kidservice.kidID.value,
    });
  }
  getAdvice(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/getAdvice', data);
  }
  getDefiImage(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/getImage', data);
  }
  sendFeedback(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/sendFeedback', data);
  }
  getHomework(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/getHomework', data, {
      observe: 'response',
    });
  }
  checkCredentials(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/checkCredentials', data);
  }
  setCredentials(data): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/api/setPronoteCredentials',
      data
    );
  }
  getHomeworkImage(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/updateHomework', data);
  }
  checkHomework(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/checkHomework', data);
  }
}
